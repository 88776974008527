<template>
  <!-- Reference: https://fontawesome.com/docs/web/use-with/vue/style -->
  <span @click="onClickElement" @mouseout="mouseOut" @mouseover="mouseOver">
    <font-awesome-icon
      v-if="provider === 'font-awesome' && iconName"
      :beat="beat"
      :beat-fade="beatFade"
      :border="border"
      :bounce="bounce"
      :fade="fade"
      :fixed-width="fixedWidth"
      :flash="flash"
      :flip="flip"
      :icon="{
        prefix: solidOnHover && hover ? 'fas' : faIconPrefix,
        iconName: iconName,
      }"
      :inverse="inverse"
      :list-item="listItem"
      :mask="mask"
      :pull="pull"
      :pulse="pulse"
      :rotation="rotation"
      :shake="shake"
      :size="size"
      :spin="spin"
      :spin-pulse="spinPulse"
      :spin-reverse="spinReverse"
      :swap-opacity="swapOpacity"
      :symbol="symbol"
      :title="title"
      :transform="transform"
    />
  </span>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: { FontAwesomeIcon },
  props: {
    /**
     * Icon name without any provider specific prefixes (added automatically)
     */
    iconName: {
      type: String,
      required: true,
    },
    /**
     * FA icon style 'solid' or 'light'
     */
    prefix: {
      type: String,
      required: false,
      default: 'light',
    },
    /**
     * Iconset provider
     * @values font-awesome
     */
    provider: {
      type: String,
      required: false,
      default: 'font-awesome',
    },
    beat: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    fade: {
      type: Boolean,
      default: false,
    },
    fixedWidth: {
      type: Boolean,
      default: false,
    },
    flash: {
      type: Boolean,
      default: false,
    },
    flip: {
      type: [Boolean, String],
      default: false,
    },
    mask: {
      type: [Object, Array, String],
      default: null,
    },
    listItem: {
      type: Boolean,
      default: false,
    },
    pull: {
      type: String,
      default: null,
    },
    pulse: {
      type: Boolean,
      default: false,
    },
    rotation: {
      type: [String, Number],
      default: null,
    },
    swapOpacity: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    spin: {
      type: Boolean,
      default: false,
    },
    spinPulse: {
      type: Boolean,
      default: false,
    },
    spinReverse: {
      type: Boolean,
      default: false,
    },
    transform: {
      type: [String, Object],
      default: null,
    },
    symbol: {
      type: [Boolean, String],
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    bounce: {
      type: Boolean,
      default: false,
    },
    shake: {
      type: Boolean,
      default: false,
    },
    beatFade: {
      type: Boolean,
      default: false,
    },
    solidOnHover: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { library, config } = useFontAwesomeLibrary();

    return {
      library,
      config,
    };
  },
  data() {
    return {
      hover: false,
    };
  },

  computed: {
    faIconPrefix() {
      if (this.prefix === 'solid') {
        return 'fas';
      } else {
        return 'fal';
      }
    },
  },

  methods: {
    mouseOver() {
      this.hover = true;
      this.$emit('mouseover');
    },
    mouseOut() {
      this.hover = false;
      this.$emit('mouseout');
    },
    onClickElement(event) {
      this.$emit('click', event);
    },
  },
};
</script>
