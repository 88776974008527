import { library, config } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faArrowLeftLong,
  faArrowRightLong,
  faArrowTurnDownLeft,
  faBriefcase,
  faBuilding,
  faCat,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCircleQuestion,
  faClipboardListCheck,
  faCoins,
  faCopy,
  faEnvelope,
  faEuroSign,
  faFile,
  faFileLines,
  faFileSignature,
  faGlobe,
  faGraduationCap,
  faHandshakeAngle,
  faHouseChimneyUser,
  faHouseDay,
  faHouseHeart,
  faImages,
  faLaptopMobile,
  faMobile,
  faPen,
  faPiggyBank,
  faQuoteRight,
  faQuoteLeft,
  faSearch,
  faShareNodes,
  faShieldCheck,
  faShieldHalved,
  faTrash,
  faUser,
  faUsers,
  faUserCheck,
  faUserGroup,
  faUserLargeSlash,
  faUserShield,
  faXmark,
  faUserXmark,
  faDog,
  faFishCooked,
  faSquirrel,
  faDove,
  faRabbit,
  faTriangleExclamation,
  faBoxArchive,
  faLocationDot,
  faCircleSmall,
  faArrowsUpDownLeftRight,
  faArrowDownAZ,
  faFileContract,
  faUserPlus,
  faLightbulbOn,
  faPlus,
  faPlusLarge,
  faBanSmoking,
  faPeopleArrows,
  faFileInvoiceDollar,
  faCouch,
  faBroom,
  faFileExcel,
  faLink,
  faBars,
  faRightFromLine,
  faPlay,
  faArrowRightFromBracket,
  faBell,
  faArrowDownLong,
  faSignature,
  faFilePdf,
  faMoneyCheckDollarPen,
  faUserHeadset,
  faKey,
  faArrowRotateLeft,
  faAt,
  faMoneyCheckPen,
  faCreditCard,
  faFilterList,
  faListOl,
  faFileCheck,
  faBallotCheck,
  faArrowUpRight,
  faHeadset,
  faWallet,
  faFileShield,
  faCommentSmile,
  faComments,
  faCalendarCheck,
  faHandHoldingHeart,
  faFaceSmile,
  faGavel,
  faLaptop,
  faMagnifyingGlass,
  faExclamationCircle,
  faScrubber,
  faHammer,
  faStars,
  faMeter,
  faHouse,
  faFileMagnifyingGlass,
  faHouseChimneyCrack,
  faGem,
  faPrint,
  faImage,
  faCloudArrowUp,
  faCalendarCirclePlus,
  faPaintRoller,
  faHouseCrack,
  faClock,
  faCircleXmark,
  faVacuum,
  faSoap,
  faHandSparkles,
  faStar,
  faCircleExclamation,
  faFileCirclePlus,
  faMoneyBillWave,
  faCalendarXmark,
  faCalendarPen,
  faCalendarCircleMinus,
  faCloudArrowDown,
  faFolderOpen,
  faCircleInfo,
  faCalendarDay,
  faHourglassStart,
  faShare,
  faLockKeyhole,
  faDoorOpen,
  faUserClock,
  faEllipsisVertical,
  faCirclePause,
  faBellOn,
  faEnvelopeDot,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCircleSmall as faCircleSmallSolid,
  faQuoteRight as faQuoteRightSolid,
  faQuoteLeft as faQuoteLeftSolid,
  faBell as faBellSolid,
  faUserHeadset as faUserHeadsetSolid,
  faKey as faKeySolid,
  faFileSignature as faFileSignatureSolid,
  faPen as faPenSolid,
  faTrash as faTrashSolid,
  faShieldCheck as faShieldCheckSolid,
  faCheck as faCheckSolid,
  faCircleCheck as faCircleCheckSolid,
  faCircleDot as faCircleDotSolid,
  faScrubber as faScrubberSolid,
  faBellOn as faBellOnSolid,
  faEllipsisVertical as faEllipsisVerticalSolid,
} from '@fortawesome/pro-solid-svg-icons';
import type { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';
import zlotySign from '~/assets/faCustomIcons/zloty.svg';
import fbLogo from '~/assets/faCustomIcons/fb-logo.svg';
import { faBellOn as faBellOnDuotone } from '@fortawesome/pro-duotone-svg-icons';

/* Custom Icons */
/* Adding a new custom icon:
 * 1. Add it into /assets/icons folder
 * 2. Write library.add with customRendinIcon(iconName, icon) function
 */
library.add(customRendinIcon('zloty-sign', zlotySign));
library.add(customRendinIcon('fb-logo', fbLogo));

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
  faArrowTurnDownLeft,
  faArrowLeftLong,
  faUserShield,
  faArrowRightLong,
  faBriefcase,
  faCat,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faCheckCircle,
  faCopy,
  faXmark,
  faCircleQuestion,
  faCoins,
  faLaptopMobile,
  faFile,
  faFileLines,
  faFileSignature,
  faGraduationCap,
  faEuroSign,
  faHouseChimneyUser,
  faPen,
  faUserCheck,
  faUserLargeSlash,
  faUser,
  faUsers,
  faHandshakeAngle,
  faShieldHalved,
  faShieldCheck,
  faHouseHeart,
  faMobile,
  faPiggyBank,
  faQuoteRight,
  faQuoteLeft,
  faTrash,
  faGlobe,
  faImages,
  faSearch,
  faShareNodes,
  faUserGroup,
  faBuilding,
  faClipboardListCheck,
  faEnvelope,
  faHouseDay,
  faArrowLeft,
  faUserXmark,
  faDog,
  faFishCooked,
  faSquirrel,
  faDove,
  faRabbit,
  faUserXmark,
  faTriangleExclamation,
  faBoxArchive,
  faLocationDot,
  faCircleSmall,
  faCircleSmallSolid,
  faArrowsUpDownLeftRight,
  faArrowDownAZ,
  faArrowsUpDownLeftRight,
  faFileContract,
  faUserPlus,
  faLightbulbOn,
  faPlus,
  faPlusLarge,
  faBanSmoking,
  faPeopleArrows,
  faFileInvoiceDollar,
  faCouch,
  faBroom,
  faFileExcel,
  faQuoteRightSolid,
  faLink,
  faBars,
  faRightFromLine,
  faPlay,
  faArrowRightFromBracket,
  faBell,
  faBellSolid,
  faArrowDownLong,
  faSignature,
  faFilePdf,
  faMoneyCheckDollarPen,
  faQuoteLeftSolid,
  faUserHeadset,
  faUserHeadsetSolid,
  faKey,
  faKeySolid,
  faFileSignatureSolid,
  faPenSolid,
  faTrashSolid,
  faArrowRotateLeft,
  faAt,
  faMoneyCheckPen,
  faCreditCard,
  faFilterList,
  faCreditCard,
  faListOl,
  faFileCheck,
  faBallotCheck,
  faArrowUpRight,
  faShieldCheckSolid,
  faHeadset,
  faCheckSolid,
  faWallet,
  faFileShield,
  faCommentSmile,
  faComments,
  faCalendarCheck,
  faHandHoldingHeart,
  faFaceSmile,
  faGavel,
  faLaptop,
  faMagnifyingGlass,
  faExclamationCircle,
  faCircleCheckSolid,
  faCircleDotSolid,
  faScrubberSolid,
  faScrubber,
  faHammer,
  faStars,
  faMeter,
  faHouse,
  faFileMagnifyingGlass,
  faHouseChimneyCrack,
  faGem,
  faPrint,
  faImage,
  faCloudArrowUp,
  faCalendarCirclePlus,
  faCloudArrowUp,
  faPaintRoller,
  faHouseCrack,
  faClock,
  faCircleXmark,
  faVacuum,
  faSoap,
  faHandSparkles,
  faStar,
  faCircleExclamation,
  faFileCirclePlus,
  faMoneyBillWave,
  faCalendarXmark,
  faCalendarPen,
  faCalendarCircleMinus,
  faCloudArrowDown,
  faFolderOpen,
  faCircleInfo,
  faCalendarDay,
  faHourglassStart,
  faShare,
  faLockKeyhole,
  faEnvelopeDot,
  faBellOn,
  faBellOnSolid,
  faBellOnDuotone,
  faDoorOpen,
  faCirclePause,
  faEllipsisVertical,
  faEllipsisVerticalSolid,
  faUserClock,
);

/**
 * Creates a Font Awesome library object
 * Custom icon has to be added to the library and can be used just by name
 * @returns {IconDefinition}
 */
function customRendinIcon(iconName: string, icon: string): IconDefinition {
  return {
    prefix: 'fal',
    // Ugly cast, because we use custom icons, which have their own name
    iconName: iconName as IconName,
    icon: [1000, 1000, [], '', icon],
  };
}

export const useFontAwesomeLibrary = () => {
  return {
    library,
    config,
  };
};
